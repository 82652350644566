import * as React from "react";
import { Helmet } from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";

import "../styles/index.scss";
import "../styles/privacy.scss";

const Legal = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Huet & Haie | Mentions légales</title>
        <meta
          name="description"
          content="Huet & Haie, situé à Vair-sur-Loire, près d’Ancenis, vous accompagne dans vos travaux d’aménagements et de restructuration de vos extérieurs, pour tous types de maisons individuelles ou d’habitats collectifs."
        />
        <link rel="canonical" href="https://huetethaie.fr/" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Head />
      <Header />
      <main id="main" className="site-main" role="main">
        <section id="legal" className="privacy">
          <header>
            <div className="h1">Mentions légales</div>
          </header>
          <div className="container privacy__container">
            <div className="h2">Site huetethaie.fr édité par Huet & Haie</div>
            <p>
              La Foucherie, 44150 VAIR-SUR-LOIRE
              <br />
              Tél. : 02 40 98 39 48
              <br />
              Responsable de publication : M. Geibig Bruno / Mme Lucette Poupart
            </p>

            <div className="h2">Conception et réalisation du Site huetethaie.fr</div>
            <p>
              Agence ZOAN, SARL au capital de 15 000 € et immatriculée au
              Registre du Commerce et des Sociétés de Nantes sous le numéro 500
              594 361. Le siège social de la société ZOAN se situe au 20 rue
              Michel Grimault, 44110 Châteaubriant - France (
              <a href="zoan.fr">zoan.fr</a> /{" "}
              <a href="tel:+33240288094">02.40.28.80.94</a>).
            </p>

            <div className="h2">Hébergement</div>
            <p>
              OVH 2 rue Kellermann – 59100 Roubaix – France SAS au capital de 10
              069 020 € RCS Lille Métropole 424 761 419 00045 Code APE 2620Z N°
              TVA : FR 22 424 761 419
            </p>

            <div className="h2">Utilisateur</div>

            <p>
              Toute personne physique, agissant pour son propre compte ou non
              (notamment en qualité de représentant d'une personne morale) dans
              un contexte professionnel, se connectant, naviguant sur le Site huetethaie.fr
              et/ou qui utilise, le cas-échant, le ou les fonctionnalités et/ou
              services associés.
            </p>
            <div className="h2">Informations/ Données Personnelles</div>
            <p>
              Toutes informations qui permettent, sous quelque forme que ce
              soit, directement ou non, l’identification des personnes physiques
              auxquelles elles s’appliquent.
            </p>
            <div className="h2">Prestataire</div>
            <p>
              Toute personne physique ou morale, sous-traitant ou non, agissant
              sur demande de l'Editeur notamment dans la création, maintenance,
              mise à jour hébergement du Site huetethaie.fr, voire le cas- échéant produit ou
              apporte du Contenu.
            </p>
            <div className="h2">Tiers</div>
            <p>
              Toute personne physique ou morale qui n'intervient pas, de manière
              directe ou indirecte, notamment dans la création, maintenance,
              mise à jour hébergement du Site huetethaie.fr et qui ne produit pas de Contenu
              pour le compte de l'Editeur.
            </p>
            <div className="h2">
              Conditions Générales d'Utilisation (CGU)/Mentions légales/
              Politique de confidentialité
            </div>
            <p>
              Documentation mis à disposition de l'Utilisateur par l'Editeur
              afin de l'informer notamment sur ses droits et obligations. Les
              CGU régissent l'accès au Site huetethaie.fr ainsi que l’utilisation des
              fonctionnalités éventuellement proposées. Les mentions légales
              répondent aux obligations d’information de l'Utilisateur du Site huetethaie.fr.
              La politique de confidentialité explique et justifie la collecte
              voire le traitement des Données Personnelles de l'Utilisateur.
            </p>
            <div className="h2">Contenu</div>
            <p>
              Tous documents, informations, images, textes, logos, liens etc..
              renseignés ou référencés sur le Site huetethaie.fr.
            </p>
            <div className="h2">Bases légales ou réglementaires</div>
            <p>
              Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
              fichiers et aux libertés, loi n° 2004-575 du 21 juin 2004 pour la
              confiance dans l'économie numérique, recommandations CNIL,
              règlement UE 2016/679 du Parlement Européen et du Conseil du 27
              avril 2016 relatif à la protection des personnes physiques à
              l'égard du traitement des données à caractère personnel et à la
              libre circulation de ces données, et abrogeant la directive
              95/46/CE dit règlement général sur la protection des données -
              RGPD, dispositions particulières du Code de la Propriété
              Intellectuelle (CPI) et du Code pénal (L. 226-16), ainsi que plus
              généralement toutes dispositions réglementaires ou légales qui
              pourraient trouver application en pareil cas.
            </p>
            <p>
              L'Utilisateur se déclare informé que les mentions légales/CGU du
              Site huetethaie.fr doivent être considérées comme lui apportant une information
              complémentaire mais indissociable de la{" "}
              <AnchorLink to="/politique-de-confidentialite/#privacy">
                politique de confidentialité accessible en suivant ce lien
              </AnchorLink>
              .
            </p>
            <div className="h2">Présentation du Site huetethaie.fr</div>
            <p>
              Ce Site huetethaie.fr a pour objectif premier de communiquer à l'Utilisateur une
              information sur les services susceptibles d'être proposés par
              l'Editeur, ainsi que les prestations régulièrement réalisées pour
              le compte de ses clients.
            </p>
            <p>
              Malgré le soin apporté lors de l'édition, la maintenance ainsi que
              la mise à jour du Site huetethaie.fr l'Editeur ne saurait engager sa
              responsabilité en raison d'omissions ou de carences, de son fait
              ou du fait de Tiers ou Prestataires.
            </p>

            <p>
              Tout Contenu renseigné ou référencé sur le Site huetethaie.fr est communiqué à
              titre indicatif, modifiable sans préavis et ne peut être considéré
              comme exhaustif.
            </p>
            <p>
              En se connectant et/ou en naviguant sur ce Site huetethaie.fr et/ou en
              utilisant, le cas-échéant, les services qui pourraient y être
              associés, l’Utilisateur reconnaît de manière non équivoque
              accepter au préalable pleinement et entièrement les présentes.
            </p>
            <p>
              Ces CGU étant modifiables à tout moment, sans préavis,
              l'Utilisateur est donc invité à s'y référer régulièrement et dans
              l'idéal préalablement à toute nouvelle connexion.
            </p>
            <p>
              Le Site huetethaie.fr est en principe accessible à tout moment par l’Utilisateur
              ; toutefois des interruptions, prévisibles ou non, peuvent se
              produire, qu'il s'agisse de maintenance technique (dans la mesure
              du possible l'Editeur avertira au préalable l'Utilisateur) ou
              qu'il s'agisse d'une défaillance d'un Prestataire (hébergeur par
              exemple) ou Tiers (coupure d'électricité par ex).
            </p>
            <p>
              En se connectant sur le Site huetethaie.fr, l'Utilisateur accepte que ses
              Données Personnelles soient susceptibles d'être recueillies,
              traitées, analysées par l'Editeur ou tout Prestataire habilité.
            </p>
            <p>
              Si le Site huetethaie.fr renvoi, au travers de liens ou toute autre procédure
              assimilée, sur des Site huetethaie.frs tiers, l'Utilisateur reconnaît et
              accepte, sans recours à quelque titre que cela soit contre
              l'Editeur, que ses Données Personnelles puissent être récupérées,
              collectées et/ou traiter par tout Tiers.
            </p>
            <p>
              L'Utilisateur se reconnait par ailleurs informé et accepte, qu'à
              défaut de renseigner certaines Données Personnelles (ou renseigner
              ses Données Personnelles totalement ou partiellement de manière
              volontairement erronée) son "expérience Utilisateur" soit limitée
              (en ce compris l'utilisation d'un ou plusieurs services associés
              au Site huetethaie.fr le cas-échéant) et/ou que l'Editeur soit empêché de
              répondre ou n'apporte qu'une réponse partielle à une ou plusieurs
              de ses demandes.
            </p>
            <div className="h2">Responsabilité</div>
            <p>
              Dès lors qu'il existe sur le Site huetethaie.fr des espaces interactifs
              permettant à l'Utilisateur d'interagir l'Editeur se réserve le
              droit, à tout moment, sans mise en demeure ou avertissement
              préalable, de supprimer tout contenu (document, texte, image
              etc...) susceptible de contrevenir à la législation applicable en
              France, notamment celle relative à la protection des Données
              Personnelles.
            </p>
            <p>
              L'Utilisateur se déclare averti et accepte, le cas-échéant, que sa
              responsabilité civile et/ou pénale puisse être engagée sur le
              fondement d'une action judiciaire de l'Editeur (ou son conseil),
              tout tiers ou autorité habilitée notamment en cas de communication
              injurieuse, diffamante, raciste, pornographique etc.... nonobstant
              le support utilisé.
            </p>
            <p>
              L'Editeur se réserve au surplus le droit de transmettre à toute
              autorité habilitée le Contenu déposé par un Utilisateur sur le
              Site huetethaie.fr si celui-ci est susceptible d'enfreindre la législation
              applicable en France.
            </p>
            <p>
              L'Editeur, l'Hébergeur ou l'Intégrateur, sauf exceptions légales,
              ne peuvent être, individuellement ou collectivement, tenus
              responsables de dommage(s) (in)direct(s), bug, incompatibilité,
              virus, malware etc... subis par le matériel de l'Utilisateur
              permettant d'accéder au Site huetethaie.fr et/ou à ses fonctionnalités.
            </p>
            <p>
              De la même manière l'Utilisateur s'interdit expressément toute
              action judiciaire ou assimilé à l'encontre sur le fondement de la
              perte d'une chance, d'un marché consécutif à l'utilisation du Site huetethaie.fr
              et/ou à une démarche de l'Editeur suite à une demande de sa part.
            </p>
            <p>
              Afin d'éviter tout problème éventuel l'Utilisateur s'engage à
              utiliser un matériel récent, notoirement protégé contre les virus
              avec un navigateur de génération récente pour accéder au Site huetethaie.fr et à
              ses fonctionnalités.
            </p>
            <div className="h2">Propriété intellectuelle</div>
            <p>
              Peu importe le moyen ou procédé mis en œuvre, excepté autorisation
              préalable écrite de l'Editeur, la représentation, reproduction,
              modification, publication ou adaptation du Contenu du Site huetethaie.fr ou d'un
              de ses éléments est interdite.
            </p>
            <p>
              Tout Utilisateur ne respectant pas les prescriptions visées
              ci-dessus pourra être attrait devant tout tribunal compétent au
              risque de se voir condamner, conformément aux dispositions des
              articles L.335-2 et suivants du Code de la Propriété
              Intellectuelle (CPI), sur le fondement d'une ou plusieurs
              infractions, notamment celles constitutives de contrefaçon.
            </p>
            <p>
              Jusqu'à preuve contraire l'Editeur est présumée être propriétaire
              des droits de propriété intellectuelle ou disposer d'un droit
              d'usage légitime de l'ensemble des éléments accessibles sur le
              Site huetethaie.fr (logos, graphismes, logiciels, textes, images etc...).
            </p>
            <p>
              L'Utilisateur est informé que les bases de données sont protégées
              par les dispositions de la loi du 1er juillet 1998 transposant la
              directive 96/9 du 11 mars 1996 relative à la protection juridique
              des bases de données.
            </p>
            <div className="h2">Contenu tiers</div>
            <p>
              Le Site huetethaie.fr peut donner accès à du contenu notamment présenté, créé,
              développé, exploité et/ou diffusé par des Tiers ou travers d'un ou
              plusieurs liens hypertextes.
            </p>
            <p>
              L'Editeur, n'ayant aucune possibilité de le vérifier, le modifier
              ou faire modifier, décline toute responsabilité, à quelque titre
              que ce soit, pour le contenu rendu accessible par ces liens.
            </p>
            <div className="h2">Litiges</div>
            <p>
              La loi française est seul compétente à l'exclusion des règles de
              conflit de loi et ce même si l'Utilisateur est régulièrement
              domicilié hors de la France (DROM, DOM-ROM, POM, Corse inclus)
              et/ou de nationalité étrangère et/ou se connecte sur le Site huetethaie.fr d'un
              territoire situé hors de France (DROM, DOM- ROM, POM, Corse
              inclus).
            </p>
            <p>
              Tout litige éventuel, à défaut d'une vaine tentative de
              conciliation amiable, en rapport avec le Site huetethaie.fr ou l'un de ses
              éléments sera soumis à la compétence exclusive des tribunaux
              relevant du ressort de la Cour d'appel de RENNES, même pour les
              procédures d'urgence, nonobstant pluralité de défendeurs ou appels
              en garantie.
            </p>
            <p>
              Toute demande ou réclamation concernant les mentions légales/CGU à
              effectuer via notre{" "}
              <AnchorLink to="/#contact">formulaire de contact</AnchorLink>.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Legal;
